import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Select from "react-select"

const AddEditPlan = () => {
    const { id } = useParams()
    const [form, setform] = useState({
        name: '',
        description: '',
        type:'',
        static_qr_codes: '',
        dynamic_qr_code: '',
        scans_per_month: '',
        tracking_analytics: '',
        advertisement: '',
        branding: '',
        affiliate_commission: '',
        bulk_upload: false,
        full_design_option: false,
        save_design_as_template: false,
        qr_code_scanner: false,
        excel_download: false,
        max_file_size: '',
        customer_support: '',
        max_folders: '',
        set_password_protection: false,
        set_expiry_of_qr_codes_by_time: false,
        set_expiry_of_qr_codes_by_scans: false,
        set_GPS_tracking_of_qr_codes: false
    })
    const [originalPricing, setOriginalPricing] = useState([]); 
    const [oneMonth, setoneMonth] = useState({ unit_amount: '', interval: 'month', interval_count: 1 })
    // const [threeMonth, setthreeMonth] = useState({ unit_amount: '', interval: 'month', interval_count: 3 })
    // const [sixMonth, setsixMonth] = useState({ unit_amount: '', interval: 'month', interval_count: 6 })
    const [year, setyear] = useState({ unit_amount: '', interval: 'month', interval_count: 12 })
    const history = useHistory()
    const planType = [{
        value: 'free', label: 'Free'
    },
    {
        value: 'paid', label: 'Paid'
    }
    ]

    console.log(form,"dfhdkjhf")

    const mapPricingToPayloadWithoutId = (original, current) => {
        const { interval_count, stripe_price_id, ...rest } = { ...original, ...current }; 
        if (interval_count === 1) return  { ...rest, interval_count, stripe_price_id } 
        if (interval_count === 12) return { ...rest, interval_count, stripe_price_id }
        return {};
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        let pricingPayload;
    
        if (!id) {
            // Create pricingPayload as an object with oneMonth and year as properties
            pricingPayload = {pricing:[
                mapPricingToPayloadWithoutId(oneMonth, oneMonth),
                mapPricingToPayloadWithoutId(year, year),
            ]}
        } else {
            // For editing, compare originalPricing with current items
            const editedPricing = originalPricing
                .map((originalItem) => {
                    const currentItem = [oneMonth, year].find(
                        (itm) => itm.interval_count === originalItem.interval_count
                    );
                    return currentItem && isPricingEdited(originalItem, currentItem)
                        ? mapPricingToPayload(originalItem, currentItem)
                        : null;
                })
                .filter(Boolean);
    
            pricingPayload = editedPricing.reduce((acc, item) => {
                return { ...acc, ...item };
            }, {});
        }
    
        let payload = {
            ...form,
            type: form?.type?.value,
            ...pricingPayload,
        };
    
        if (Object.keys(pricingPayload).length === 0) {
            delete payload.one_month_price;
            delete payload.twelve_month_price; // Corrected typo here
        }

        if(form?.type?.value == "free"){
            delete payload.pricing
        }
    
        if (!id) {
            // Creating a new subscription plan
            ApiClient.post(`subscription-plan`, payload).then((res) => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message);
                    }, 100);
                    history.push(`/plan`);
                }
            });
        } else {
            // Updating an existing subscription plan
            delete payload.on_trial;
    
            ApiClient.put(`subscription-plan`, { ...payload, id }).then((res) => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message);
                    }, 100);
                    history.push(`/plan`);
                }
            });
        }
    };
    
    
    
    

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(`subscription-plan?id=${id}`).then(res => {
                if (res.success) {
                    let data = {
                        name: res?.data?.name,
                        // type:res?.data?.type,
                        type:{ value:res?.data?.type , label:res?.data?.type},
                        on_trial:res?.data?.on_trial,
                        trial_period_days:res?.data?.trial_period_days,
                        description: res?.data?.description,
                        // type:res?.data?.type,
                        static_qr_codes: res?.data?.static_qr_codes,
                        dynamic_qr_code: res?.data?.dynamic_qr_code,
                        scans_per_month: res?.data?.scans_per_month,
                        tracking_analytics: res?.data?.tracking_analytics,
                        advertisement: res?.data?.advertisement,
                        branding: res?.data?.branding,
                        affiliate_commission: res?.data?.affiliate_commission,
                        bulk_upload: res?.data?.bulk_upload,
                        full_design_option: res?.data?.full_design_option,
                        save_design_as_template: res?.data?.save_design_as_template,
                        qr_code_scanner: res?.data?.qr_code_scanner,
                        excel_download: res?.data?.excel_download,
                        max_file_size: res?.data?.max_file_size,
                        customer_support: res?.data?.customer_support,
                        max_folders: res?.data?.max_folders,
                        set_password_protection: res?.data?.set_password_protection,
                        set_expiry_of_qr_codes_by_time: res?.data?.set_expiry_of_qr_codes_by_time,
                        set_expiry_of_qr_codes_by_scans: res?.data?.set_expiry_of_qr_codes_by_scans,
                        set_GPS_tracking_of_qr_codes: res?.data?.set_GPS_tracking_of_qr_codes
                    }
                    setform(data)
                    setOriginalPricing(res?.data?.pricing || [])
                    res.data?.pricing.filter(itm => {
                        if (itm?.interval_count == 1) {
                            setoneMonth({ ...oneMonth, unit_amount: itm?.unit_amount,stripe_price_id : itm?.stripe_price_id })
                        }
                        else if (itm?.interval_count == 3) {
                            setthreeMonth({ ...threeMonth, unit_amount: itm?.unit_amount,stripe_price_id : itm?.stripe_price_id })
                        }
                        else if (itm?.interval_count == 6) {
                            setsixMonth({ ...sixMonth, unit_amount: itm?.unit_amount,stripe_price_id : itm?.stripe_price_id })
                        }
                        else {
                            setyear({ ...year, unit_amount: itm?.unit_amount,stripe_price_id : itm?.stripe_price_id })
                        }
                    })
                }
                loader(false)
            })
        }
    }, [])

    const isPricingEdited = (original, current) => {
        return (
            original.unit_amount !== current.unit_amount ||
            original.interval_count !== current.interval_count ||
            original.interval !== current.interval
        );
    }; 

    const mapPricingToPayload = (original, current) => {
        const { interval_count, stripe_price_id, ...rest } = { ...original, ...current }; 
        if (interval_count === 1) return { one_month_price: { ...rest, interval_count, stripe_price_id } };
        if (interval_count === 12) return { tweleve_month_price: { ...rest, interval_count, stripe_price_id } };
        return {};
    };



    return (
        <Layout>
            <div class="rowAdd d-flex "> <h2 class="name_heading mb-0">{!id ? 'Add' : 'Edit'} Plan</h2> </div>
            
            <form onSubmit={handleSubmit}>
                <div className="form-row mt-4">
                    <div className="col-md-6 mb-3">
                        <label>Name <span className="text-danger">*</span></label>
                        <input type='text' value={form?.name} onChange={e => {
                            const newName = e.target.value.replace(/[^a-zA-Z]/g, '')
                            setform({ ...form, name: newName });
                        }} className="form-control" placeholder="Enter Name" required />
                    </div>
               

                    <div className="col-md-6 mb-3">
                            <label>Plan type <span className="text-danger">*</span></label>
                        <Select
                            value={form?.type}
                            onChange={e => {
                                setform({ ...form, type: e });
                            }}
                            isDisabled={id ? true : false}
                            options={planType}
                        />
                        </div>
                        <div className="col-md-12 mb-3">
                        <label>Description</label>
                        <textarea value={form?.description} onChange={e => setform({ ...form, description: e.target.value })} className="form-control" placeholder="Enter Description here" id="floatingTextarea2"></textarea>
                    </div>
                    {form?.type?.value == 'paid' && <div className="col-md-6 mb-3">
                        <label>Trial Plan:</label>
                       <div>
                        <input type='checkbox' disabled={id}  checked={form?.on_trial} onChange={e => setform({ ...form, on_trial: e.target.checked })} />  Trial Period
                  </div>
                    </div>}

                    {(form?.on_trial && form?.type?.value == 'paid') && <div className="col-md-6 mb-3">
                        <label>Trial Period Days : <span className="text-danger">*</span></label>
                        <input type='text' disabled={id} value={form?.trial_period_days} onChange={e => setform({ ...form, trial_period_days: e.target.value })} minLength={1} maxLength={5} className="form-control w-100" placeholder="Enter Days" required />
                    </div>}

                   {form?.type?.value != "free" && <div className="col-12"> <div className="row mt-3 px-2">
                   <div className="col-md-12">     <h4>Pricing</h4></div>
                        <div className="col-md-6 mb-3">
                            <label>1 Month <span className="text-danger">*</span></label>
                            <input type='text'   value={oneMonth?.unit_amount} onChange={e => setoneMonth({ unit_amount: methodModel.isNumber(e), interval: 'month', interval_count: 1 })} minLength={1} maxLength={5} className="form-control w-100" placeholder="Enter Price" required />
                        </div>
                        {/* <div className="col-md-12 mb-2">
                            <label>3 Month</label>
                            <input type='text' value={threeMonth?.unit_amount} onChange={e => setthreeMonth({ unit_amount: methodModel.isNumber(e), interval: 'month', interval_count: 3 })} minLength={1} maxLength={5} className="form-control w-50" placeholder="Enter Price" />
                        </div>
                        <div className="col-md-12 mb-2">
                            <label>6 Month</label>
                            <input type='text' value={sixMonth?.unit_amount} onChange={e => setsixMonth({ unit_amount: methodModel.isNumber(e), interval: 'month', interval_count: 6 })} minLength={1} maxLength={5} className="form-control w-50" placeholder="Enter Price" />
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label>12 Month <span className="text-danger">*</span></label>
                            <input type='text' value={year?.unit_amount} onChange={e => setyear({ unit_amount: methodModel.isNumber(e), interval: 'month', interval_count: 12 })} minLength={1} maxLength={5} className="form-control w-100" placeholder="Enter Price" required />
                        </div>
                    </div></div>}
                    <div className="row mt-2 px-2">
                        <h4>Features</h4>
                        {/* Input Fields */}
                        <div className="col-md-6 mb-3">
                            <label>Static Qr Code <span className="text-danger">*</span></label>
                            <input type='text' value={form?.static_qr_codes} onChange={e => setform({ ...form, static_qr_codes: e.target.value })} maxLength="20" className="form-control" placeholder="Static Qr Code" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Dynamic Qr Code <span className="text-danger">*</span></label>
                            <input type='text' value={form?.dynamic_qr_code} onChange={e => setform({ ...form, dynamic_qr_code: methodModel.isNumber(e) })} minLength={1} maxLength={5} className="form-control" placeholder="Dynamic Qr Code" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Scans per Month <span className="text-danger">*</span></label>
                            <input type='text' value={form?.scans_per_month} onChange={e => setform({ ...form, scans_per_month: e.target.value })} maxLength="20" className="form-control" placeholder="Scans per Month" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Tracking Analytics <span className="text-danger">*</span></label>
                            <input type='text' value={form?.tracking_analytics} onChange={e => setform({ ...form, tracking_analytics: e.target.value })} maxLength="20" className="form-control" placeholder="Tracking Analytics" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Advertisement <span className="text-danger">*</span></label>
                            <input type='text' value={form?.advertisement} onChange={e => setform({ ...form, advertisement: e.target.value })} maxLength="20" className="form-control" placeholder="Advertisement" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Branding <span className="text-danger">*</span></label>
                            <input type='text' value={form?.branding} onChange={e => setform({ ...form, branding: e.target.value })} maxLength="50" className="form-control" placeholder="Branding" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Affiliate Commission	<span className="text-danger">*</span></label>
                            <input type='text' value={form?.affiliate_commission} onChange={e => setform({ ...form, affiliate_commission: methodModel.isNumber(e) })} minLength={1} maxLength={5} className="form-control" placeholder="Affiliate Commission" required />
                        </div>
                        {/* CheckBox */}
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.bulk_upload} onChange={e => setform({ ...form, bulk_upload: e.target.checked })} /> Bulk Upload
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.full_design_option} onChange={e => setform({ ...form, full_design_option: e.target.checked })} /> Full Design Options
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.save_design_as_template} onChange={e => setform({ ...form, save_design_as_template: e.target.checked })} /> Save Design as Template
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.qr_code_scanner} onChange={e => setform({ ...form, qr_code_scanner: e.target.checked })} /> QR Code Scanner
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.excel_download} onChange={e => setform({ ...form, excel_download: e.target.checked })} /> Excel Download of Analytics
                            </label>
                        </div>
                        {/* Input Fields */}
                        <div className="col-md-6 mb-3">
                            <label>Max File Size <span className="text-danger">*</span></label>
                            <input type='text' value={form?.max_file_size} onChange={e => setform({ ...form, max_file_size: e.target.value })} maxLength="10" className="form-control" placeholder="Max File Size" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Customer Support <span className="text-danger">*</span></label>
                            <input type='text' value={form?.customer_support} onChange={e => setform({ ...form, customer_support: e.target.value })} maxLength="30" className="form-control" placeholder="Customer Support" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Max Folders <span className="text-danger">*</span></label>
                            <input type='text' value={form?.max_folders} onChange={e => setform({ ...form, max_folders: methodModel.isNumber(e) })} minLength={1} maxLength={5} className="form-control" placeholder="Max Folders" required />
                        </div>
                        {/* CheckBox */}
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.set_password_protection} onChange={e => setform({ ...form, set_password_protection: e.target.checked })} /> Set Password Protection of QR Codes
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.set_expiry_of_qr_codes_by_time} onChange={e => setform({ ...form, set_expiry_of_qr_codes_by_time: e.target.checked })} /> Set Expiry of QR Codes by Time
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.set_expiry_of_qr_codes_by_scans} onChange={e => setform({ ...form, set_expiry_of_qr_codes_by_scans: e.target.checked })} /> Set Expiry of QR Codes by Scans
                            </label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="pointer">
                                <input type='checkbox' checked={form?.set_GPS_tracking_of_qr_codes} onChange={e => setform({ ...form, set_GPS_tracking_of_qr_codes: e.target.checked })} /> Set GPS Tracking of QR Codes
                            </label>
                        </div>
                    </div>
                </div>
                <div className="text-right mt-4">
                    <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { history.goBack() }}>Back</button>
                    <button type="submit" className="btn btn-primary mx-2">{!id ? 'Save' : 'Update'}</button>
                </div>
            </form>
        </Layout>
    )
}

export default AddEditPlan